<template>
  <div
    class="relative mb-36 mt-8 flex min-h-[10vh] flex-col items-center md:mb-[20rem] md:mt-12 lg:mb-72"
  >
    <span
      class="mb-1 text-center text-2xl font-bold leading-normal text-white md:mb-3 md:text-[41px]"
    >
      <slot name="title"></slot>
    </span>
    <span class="mb-10 text-center text-base text-white md:text-[29px]">
      <slot name="secondary_title"></slot>
    </span>
    <slot name="button"></slot>
  </div>
</template>
