<template>
  <BackgroundPatternWrapper>
    <NavBarSecondary />
    <LandingPageHeaderContainer>
      <template #title>
        <h1 class="whitespace-pre">
          {{ $t("intro_page.hero_section.main_title") }}
        </h1>
      </template>
      <template #secondary_title>{{
        $t("intro_page.hero_section.slogan")
      }}</template>
      <template #button>
        <TrialButton white />
      </template>
    </LandingPageHeaderContainer>
    <ProductContentContainer class="container_skew">
      <SectionProduct />
      <SectionFunctionalities />
      <SectionAdvantages />
      <SectionTestimonials />
      <SectionDataOwnership />
    </ProductContentContainer>
    <FooterContainer />
  </BackgroundPatternWrapper>
</template>
