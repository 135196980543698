<template>
  <div
    class="flex flex-1 flex-col items-center justify-center gap-6 md:justify-start lg:flex-row"
    :class="{ 'lg:flex-row-reverse': reverse }"
  >
    <video
      ref="video"
      loop
      class="rounded-sm shadow-xl lg:w-[500px] lg:rounded-lg lg:shadow-2xl xl:w-[776px]"
      muted
      playsinline
      preload="none"
      :poster="cloudStorageUrl(`function/${posterFileName}`)"
    >
      <source
        :src="cloudStorageUrl(`function/${videoName}.webm`)"
        type="video/webm"
      />
      <source
        :src="cloudStorageUrl(`function/${videoName}.mp4`)"
        type="video/mp4"
      />
      <source
        :src="cloudStorageUrl(`function/${videoName}.ogg`)"
        type="video/ogg"
      />
      <track kind="captions" src=".srt" srclang="de" label="Untertitel" />
    </video>

    <div class="group flex flex-col items-center lg:block">
      <div class="mx-5 md:max-w-sm lg:-mt-8 xl:mx-16">
        <h3
          class="text-center text-lg font-extrabold text-lexmea-blue-200 md:mb-2 md:text-2xl lg:text-left"
          v-html="title"
        ></h3>
        <p
          class="max-w-xs text-center text-sm font-normal text-lexmea-blue-400 md:max-w-none md:text-xl lg:text-left"
          v-html="description"
        ></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { cloudStorageUrl } from "~/utils/cloudStorage";

const video = ref<HTMLVideoElement>();
const root = ref(null);

withDefaults(
  defineProps<{
    videoName: string;
    posterFileName: string;
    reverse?: boolean;
    title: string;
    description: string;
  }>(),
  { reverse: false }
);

useIntersectionObserver(
  video,
  async ([{ isIntersecting }]) => {
    if (!isIntersecting) {
      video.value?.pause();
    } else {
      await video.value?.play().catch(() => {});
    }
  },
  { root, threshold: 0.0, rootMargin: "50px" }
);
</script>
